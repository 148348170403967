//import { createContext } from 'react'
//import FirebaseApp from 'firebase/app'
//import 'firebase/firestore'
import FirebaseApp from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/messaging'


const config = {
  appId: process.env.REACT_APP_APP_ID,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
}

/* const Firebase = () => {
  if (!FirebaseApp.apps.length) {
    FirebaseApp.initializeApp(config)
    FirebaseApp.firestore()
      .enablePersistence({ synchronizeTabs: true })
      .catch(err => console.error(err))
  }
} */

const initializeMessaging = async (app) => {
  console.log('Initializing messaging...');

  if (!("Notification" in window)) {
    console.log('Este navegador no soporta notificaciones');
    return;
  }

  try {
    let permission = Notification.permission;

    // Si el permiso no está concedido, lo solicitamos
    if (permission !== "granted") {
      permission = await Notification.requestPermission();
    }

    if (permission !== "granted") {
      console.log('Permiso de notificaciones no concedido');
      return;
    }

    // Registrar el service worker si no está registrado
    const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js', {
      scope: '/'
    });
    console.log('Service Worker registrado: firebase-messaging-sw.js ', registration);

    // Inicializar messaging
    const messaging = app.messaging();

    // Obtener token
    try {
      const currentToken = await messaging.getToken({
        vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
        serviceWorkerRegistration: registration
      });

      if (currentToken) {
        console.log('Token obtenido:', currentToken);
        // Aquí puedes enviar el token a tu backend
        await sendTokenToServer(currentToken);
      } else {
        console.log('No se pudo obtener el token');
      }
    } catch (tokenError) {
      console.error('Error al obtener el token:', tokenError);
    }

    // Manejar mensajes en primer plano
    messaging.onMessage((payload) => {
      console.log('Mensaje recibido en primer plano:', payload);

      const { notification } = payload;
      const notificationOptions = {
        body: notification.body,
        icon: notification.icon || '/BEFE_3.png',
        image: notification.image,
        badge: '/BEFE_3.png',
        data: payload.data || {},
        tag: notification.tag || 'default',
        requireInteraction: true,
        vibrate: [200, 100, 200]
      };

      // Mostrar notificación
      const notificationInstance = new Notification(notification.title, notificationOptions);

      // Manejar clic en la notificación
      notificationInstance.onclick = () => {
        notificationInstance.close();
        window.focus();

        // Si hay una URL en los datos, navegar a ella
        if (payload.data?.url) {
          window.location.href = payload.data.url;
        }
      };
    });


  } catch (err) {
    console.error('Error en la inicialización de messaging:', err);
  }
};

const sendTokenToServer = async (token) => {
  // Implementa aquí la lógica para enviar el token a tu backend
  try {
    // Ejemplo:
    // await fetch('tu-api/register-token', {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json' },
    //   body: JSON.stringify({ token })
    // });
  } catch (error) {
    console.error('Error al enviar token al servidor:', error);
  }
};


class Firebase {
  constructor() {
    if (!FirebaseApp.apps.length) {
      const app = FirebaseApp.initializeApp(config)

      // Inicializar messaging de manera asíncrona
      initializeMessaging(app).catch(err =>
        console.error('Error al inicializar messaging:', err)
      );

      /* FirebaseApp.firestore()
        .enablePersistence({ synchronizeTabs: true })
        .catch(err => console.error(err)) */
    }

    // instance variables
    this.db = FirebaseApp.firestore()
    this.businessInfoCollection = this.db.collection('businessInfo')
    this.clientsCollection = this.db.collection('clients')
    this.configCollection = this.db.collection('config')
    this.errorsCollection = this.db.collection('errors')
    this.orderStateCollection = this.db.collection('orderState')
    this.ordersCollection = this.db.collection('orders')
    this.productsCollection = this.db.collection('products')
    this.regionsCollection = this.db.collection('regions')
    this.staffCollection = this.db.collection('staff')
    this.usersCollection = this.db.collection('users')
  }

  // Método para obtener el token actual
  async getMessagingToken() {
    try {
      const messaging = FirebaseApp.messaging();
      return await messaging.getToken({
        vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY
      });
    } catch (error) {
      console.error('Error al obtener token:', error);
      return null;
    }
  }

  // Método para eliminar el token
  async deleteMessagingToken() {
    try {
      const messaging = FirebaseApp.messaging();
      await messaging.deleteToken();
      console.log('Token eliminado correctamente');
    } catch (error) {
      console.error('Error al eliminar token:', error);
    }
  }


}

//const FirebaseContext = createContext(null)
/*
  export { Firebase, FirebaseContext, FirebaseApp } 
  */

export default Firebase