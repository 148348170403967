import React, { useRef } from 'react';
import { makeStyles, IconButton } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        width: '98vw',
        display: 'flex',
        alignItems: 'center',
    },
    scrollContainer: {
        overflowX: 'auto',
        display: 'flex',
        scrollBehavior: 'smooth',
        width: '100%',
        '&::-webkit-scrollbar': {
            height: 10,
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#cfcfcf',
            borderRadius: 10,
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#F7F9F9',
            borderRadius: 10,
        },
    },
    navigationButton: {
        position: 'absolute',
        zIndex: 2,
        backgroundColor: 'transparent',
        top: '50%',
        transform: 'translateY(-50%)',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
    },
    leftButton: {
        left: -20,
    },
    rightButton: {
        right: -20,
    },
    content: {
        display: 'flex',
        gap: theme.spacing(2),
        padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    }
}));

const HorizontalScroll = ({ children }) => {
    const classes = useStyles();
    const scrollRef = useRef(null);

    const scroll = (direction) => {
        const container = scrollRef.current;
        const scrollAmount = container.clientWidth * 0.8;
        container.scrollBy({
            left: direction === 'left' ? -scrollAmount : scrollAmount,
            behavior: 'smooth',
        });
    };

    return (
        <div className={classes.root}>
            <IconButton
                className={`${classes.navigationButton} ${classes.leftButton}`}
                onClick={() => scroll('left')}
            >
                <ChevronLeft />
            </IconButton>
            <div className={classes.scrollContainer} ref={scrollRef}>
                <div className={classes.content}>
                    {children}
                </div>
            </div>
            <IconButton
                className={`${classes.navigationButton} ${classes.rightButton}`}
                onClick={() => scroll('right')}
            >
                <ChevronRight />
            </IconButton>
        </div>
    );
};

export default HorizontalScroll;