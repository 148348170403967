import moment from 'moment';
require('moment-timezone');
require('moment/locale/es');

moment.locale('es');

Date.prototype.addDays = function (days) {
    this.setDate(this.getDate() + days)
    return this
}

export const spanishDaysArray = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"]

export const spanishMonthArray = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']

export const isWeekend = date => {
    const dt = new Date(date)
    if (dt.getDay() === 6 || dt.getDay() === 0) {
        return true
    }
    return false
}

export const isHoliday = dates => {
    const now = new Date()
    let result = false
    dates.forEach(day => {
        day = timestampToDate(day)
        //console.log('day ', day.getDay(), now.getDay())
        //console.log('month ', day.getMonth(), now.getMonth())
        if (day.getDay() === now.getDay() && day.getMonth() === now.getMonth())
            result = true
    })
    return result
}

export const timestampToDate = ts => new Date(ts.seconds * 1000)

/**
* @return {string} YYYY-MM-DDTHH:MM
*/
export const toISOString = date =>
    date.toISOString ?
        date.toISOString().split('.')[0].substring(0, 16)
        :
        timestampToDate(date).toISOString().split('.')[0].substring(0, 16)
//'YYYY-MM-DDTHH:MM:SS'

export const toDate = date => {
    if (date.seconds) {
        return timestampToDate(date)
    } else {
        return date
    }
}

// Fecha en formato mes sistema, Enero = 0
export const toYYYYMMString = date => `${date.getFullYear()}${date.getMonth()}`



export const getDaysArray = ({ startDate, endDate, addFn, interval, excludes }) => {
    addFn = addFn || Date.prototype.addDays
    interval = interval || 1

    var retVal = []
    var current = new Date(startDate)

    //console.log('startDate', startDate)

    while (current <= endDate) {
        //console.log(current.getDay(), 'in', excludes)
        if (!excludes.includes(current.getDay())) {
            retVal.push({ date: new Date(current) })
        }
        current = addFn.call(current, interval)
    }
    return retVal
}


// mm/dd/yyyy
export const formatDate = (date) => {
    return [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
    ].join('/')
}

function padTo2Digits(num) {
    return num.toString().padStart(2, '0')
}

// Función auxiliar para convertir el formato HH:mm a minutos desde la medianoche
function convertToMinutes(timeString) {
    const [hours, minutes] = timeString.split(':').map(Number);
    return hours * 60 + minutes;
}


export const getTodayFromBusinessSchedule = (schedule = []) => {
    try {
        const now = new Date()
        const currentDay = spanishDaysArray[now.getDay()]

        return currentDay
    } catch (error) {
        console.error(error)
        return null
    }
}

export const convertScheduleBusinessDayToDate = (value) => {
    try {
        const { from = '19:00', to = '01:00', day } = value || {};

        // Si 'to' es mayor que 'from', significa que el horario corresponde al día siguiente
        const isOvernight = moment(to, 'HH:mm').isBefore(moment(from, 'HH:mm'));

        // Use moment para parsear la fecha actual y la fecha del próximo día si es necesario
        const fromDate = moment(day + ' ' + from, 'dddd HH:mm');
        const toDate = isOvernight
            ? moment(day, 'dddd').add(1, 'day').add(moment(to, 'HH:mm').diff(moment('00:00', 'HH:mm')))
            : moment(day + ' ' + to, 'dddd HH:mm');

        return {
            day,
            from,
            to,
            fromDate: fromDate.toDate(),
            toDate: toDate.toDate(),
        };
    } catch (error) {
        console.error(error);
        return value;
    }
};

export const getBusinessScheduleStatus = (businessInfo) => {
    let isOpen = false;
    let openSoon = false;
    let message = 'El local se encuentra cerrado por hoy';
    let nextOpening = null;
    let hoursToOpen = null;
    try {
        if (!businessInfo?.schedule) {
            console.error('No se encontró el horario del negocio');
            return {
                isOpen,
                openSoon,
                message,
                hoursToOpen
            };
        }
        // console.log('Se encontró el horario del negocio')
        // Obtener la fecha y hora actual
        const now = moment();
        // Obtener el número del día de la semana (0-6, donde 0 es domingo)
        const dayNumber = now.day();
        // Obtener la clave del día actual en español
        const todayKey = spanishDaysArray[dayNumber];
        // Obtener el horario del día actual del objeto businessInfo
        const todaySchedule = businessInfo?.schedule[todayKey]?.hours || [];

        // Recorrer cada intervalo horario del día actual
        for (const slot of todaySchedule) {
            // Crear objetos moment para las horas de inicio y fin del intervalo
            let start = moment(slot.start, 'HH:mm');
            let end = moment(slot.end, 'HH:mm');


            // Ajustar la hora de fin si el intervalo pasa a la madrugada del día siguiente
            if (end.isBefore(start)) {
                end.add(1, 'days');
            }

            // Verificar si la hora actual está dentro del intervalo
            if (now.isBetween(start, end)) {
                isOpen = true;
                message = '';
                break;
            } else if (now.isBefore(start)) {
                // Si la hora actual es antes del inicio del intervalo, almacenar la próxima apertura
                if (!nextOpening || start.isBefore(nextOpening)) {
                    nextOpening = start;
                }
            }
        }

        // Verificar los horarios del día actual si aún no hemos determinado que está abierto
        // control por si son las 00:01 y el local esta abierto desde el dia anterior
        if (!isOpen) {
            const yesterdayNumber = (dayNumber - 1 + 7) % 7;
            const yesterdayKey = spanishDaysArray[yesterdayNumber];
            const yesterdaySchedule = businessInfo?.schedule[yesterdayKey]?.hours || [];
            const yesterday = moment().subtract(1, 'days');
            for (const slot of yesterdaySchedule) {
                const start = moment(now)
                    .subtract(1, "days")
                    .set({
                        hour: parseInt(slot.start.split(":")[0]),
                        minute: parseInt(slot.start.split(":")[1]),
                        second: 0,
                    });

                const end = moment(now)
                    .subtract(1, "days")
                    .set({
                        hour: parseInt(slot.end.split(":")[0]),
                        minute: parseInt(slot.end.split(":")[1]),
                        second: 0,
                    });

                if (end.isBefore(start)) {
                    end.add(1, 'days');
                }

                if (now.isBetween(start, end)) {
                    isOpen = true;
                    message = '';
                    break;
                } else if (now.isBefore(start)) {
                    if (!nextOpening || start.isBefore(nextOpening)) {
                        nextOpening = start;
                    }
                }
            }
        }

        // Si el negocio no está abierto y hay una próxima apertura
        if (!isOpen && nextOpening) {
            // Calcular la diferencia en minutos y horas hasta la próxima apertura
            const diffMinutes = nextOpening.diff(now, 'minutes');
            const diffHours = nextOpening.diff(now, 'hours', true);
            hoursToOpen = diffHours;

            // Determinar el mensaje según la diferencia en minutos y horas
            if (diffMinutes <= 60) {
                openSoon = true;
                message = `Abrimos dentro de ${diffMinutes} minutos`;
            } else if (diffHours <= 24) {
                openSoon = true;
                message = `Abrimos dentro de ${Math.ceil(diffHours)} horas`;
            }
        }


    } catch (error) {
        console.error('getBusinessScheduleStatus', error)
    }
    return {
        isOpen,
        openSoon,
        message,
        hoursToOpen
    };
}