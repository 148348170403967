import React, { createContext, useEffect, useRef, useState, useMemo } from 'react'
//import firebase from 'firebase/app'
import firebase from 'firebase/compat/app'
import { isUserAdmin, isUserDelivery, isUserChef, isUserDev, getUsers, getUser } from '../firebase/obj/user'
import { getClientData, getClientsRealTime } from '../firebase/obj/client'
import { getConfig, getConfigRealTime } from '../firebase/obj/config'
import { getRegions } from '../firebase/obj/region'
import { checkTermsAndConditions } from '../utils'
import { getPaymentMethods } from '../firebase/obj/paymentMethods'
import { getBusinessInfo } from '../firebase/obj/businessInfo'
import { getOrderStates, sortSates } from '../firebase/obj/orderState'
import { getProductsCategory, getProductsRealTime } from '../firebase/obj/product'
import { getStaff } from '../firebase/obj/staff'
import { getActiveGroups, getGroupsRealTime } from '../firebase/obj/productGroup'
import { beepersRealTime } from '../firebase/obj/beepers'
import { getModules } from '../firebase/obj/modules'
//import { FlashOnTwoTone } from '@material-ui/icons'
import { useLocation } from '@reach/router';
import { getRolesFiltered } from '../firebase/obj/roles'

export const Context = createContext()


const Provider = ({ children }) => {
  const [isAuth, setIsAuth] = useState(() => {
    //console.log(window.sessionStorage.getItem('user'))
    return window.sessionStorage.getItem('user')
  })
  const [modules, setModules] = useState({})
  const [user, setUser] = useState(null)
  const [client, setClient] = useState(null)
  const [isAdmin, setAdmin] = useState(false)
  const [isDev, setDev] = useState(false)
  const [isDelivery, setDelivery] = useState(false)
  const [isChef, setChef] = useState(false)
  const [clients, setClients] = useState([])
  const [config, setConfig] = useState({})
  const [cart, setCart] = useState({ productsList: [], totalQty: 0, pickup: false })
  const [paymentMethods, setPaymentMethods] = useState([])
  const [businessInfo, setBusinessInfo] = useState({})
  const [regions, setRegions] = useState([])
  const [orderStates, setOrderStates] = useState([])
  const [products, setProducts] = useState([])
  const [productsFiltered, setProductsFiltered] = useState([])
  const [staffs, setStaffs] = useState([])
  const [productGroups, setPorductGroups] = useState([])
  const [productsCategories, setProductsCategory] = useState([])
  const [beepers, setBeepers] = useState([])
  const [search, setSearch] = useState('')
  const [showFooter, setShowFooter] = useState(true)
  const layoutContainerRef = useRef()
  const location = useLocation();

  useEffect(() => {
    if (user) {
      loadPaymentMethods()
      // fetchRegions()
      fetchOrderStates()
    }
  }, [user])

  useEffect(() => {
    if (isDelivery) {
      getModules().then(mods => setModules(mods))
    }
    if (isDelivery && orderStates.length > 0) {
      const states = orderStates.filter(s => s.value >= 60)
      setOrderStates(states)
    }
  }, [isDelivery])

  useEffect(() => {
    if (location.pathname === '/orders' && isDelivery) {
      setShowFooter(false)

    } else {
      setShowFooter(true)
    }
  }, [isDelivery, location])

  const filteredProducts = useMemo(() => {
    if (search && search.length > 0 && products.length > 0) {
      const searchLower = search.toLowerCase();
      return products.filter(p =>
        p.name.toLowerCase().includes(searchLower) ||
        p.keywords?.some(keyword => keyword.includes(searchLower))
      );
    } else {
      return products;
    }
  }, [search, products]);

  useEffect(() => {
    setProductsFiltered(filteredProducts);
  }, [filteredProducts]);

  // useEffect(() => {
  //   if (search && search.length > 0 && products.length > 0) {
  //     const searchLower = search.toLowerCase()
  //     const filtered = products.filter(p =>
  //       p.name.toLowerCase().includes(searchLower) ||
  //       p.keywords?.some(keyword => keyword.includes(searchLower))
  //     )
  //     // console.log('filtered', filtered)
  //     setProductsFiltered(filtered)
  //   } else {
  //     setProductsFiltered(products)
  //   }
  // }, [products, search])

  const loadUserPermissions = async (user) => {
    let permissions = []
    try {
      if (!user)
        return permissions

      const roles = await getRolesFiltered(user.rolesIds)
      // console.log('loadUserRoles', roles)
      permissions = roles?.map(r => r.permissions).flat() || []

    } catch (error) {
      console.error('loadUserRoles', error)
    }
    return permissions
  }

  useEffect(() => {
    // Auth listener
    const unsubscribe = firebase.auth().onAuthStateChanged(async (authUser) => {
      // console.log('auth', authUser)
      if (authUser != null) {

        // obtengo user para saber si es usuario del sistema, si no existe es solo cliente
        const userAdmin = await getUser(authUser)
        const permissions = userAdmin?.id ? await loadUserPermissions(userAdmin) : []
        // console.log('permissions', permissions)

        const clientData = await getClientData(authUser.uid)
        // console.log('user', user)
        setClient(clientData)



        const userData = {
          ...clientData,
          ...userAdmin,
          uid: authUser.uid,
          email: authUser.email,
          displayName: authUser.displayName,
          photoURL: authUser.photoURL,
          phoneNumber: authUser.phoneNumber,
          permissions
        }
        setUser(userData)

        // console.log('userData', userData)
        setIsAuth(true)
        setAdmin(userData?.admin)
        setDelivery(userData?.delivery)
        setChef(userData?.chef)
        if (clientData?.termsConditions) {
          window.sessionStorage.setItem(`termsConditions-${authUser.uid}`, `true`)
        } else {
          clientData && checkTermsAndConditions(userData)
        }
        window.sessionStorage.setItem('user', authUser.displayName)

        // isUserAdmin(user).then(res => setAdmin(res)).catch(err => console.warn(err))
        // isUserDev(user).then(res => setDev(res)).catch(err => console.warn(err))
        // isUserDelivery(user).then(res => setDelivery(res)).catch(err => console.warn(err))
        // isUserChef(user).then(res => setChef(res)).catch(err => console.warn(err))
        // setTimeout(() => checkTermsAndConditions(user), 3000)

      } else {
        //console.log('user', user)
        setIsAuth(false)
        setAdmin(false)
        setDelivery(false)
        setChef(false)
        window.sessionStorage.setItem('user', null)
      }
    });

    // Data fetching
    function fetchData() {
      try {
        // console.log('fetchData')
        getConfigRealTime(setConfig)
        getBusinessInfo().then(info => setBusinessInfo(info))
        getProductsRealTime(setProducts)
      } catch (error) {
        console.error('fetchData', error)
      }
    }

    fetchData();

    // Cleanup
    return () => {
      unsubscribe();
      fetchData(); // Si fetchData devuelve una función de limpieza
    }
  }, [])

  useEffect(() => {
    const loadData = () => {
      let cleanupFunction = () => { }
      if (isAdmin) {
        const _setState = data => {
          // console.log('getClientsRealTime from context')
          setClients(data)
        }
        getModules().then(mods => setModules(mods))
        // getStaff().then(staffs => setStaffs(staffs))
        getUsers().then(staffs => setStaffs(staffs))
        getProductsCategory().then(categories => setProductsCategory(categories))
        //getProducts().then(products => setProducts(products))
        const _getGroupsRealTime = getGroupsRealTime(setPorductGroups)
        const _getClientsRealTime = getClientsRealTime(_setState)
        const _beepersRealTime = beepersRealTime(setBeepers)
        cleanupFunction = () => {
          if (_getGroupsRealTime) {
            _getGroupsRealTime()
          }
          if (_getClientsRealTime) {
            _getClientsRealTime()
          }
          if (_beepersRealTime) {
            _beepersRealTime()
          }
        };
      } else {
        getActiveGroups().then(groups => setPorductGroups(groups))
      }
      return cleanupFunction
    }
    return loadData()
  }, [isAdmin])

  // const fetchRegions = () => getRegions().then(regions => setRegions(regions))

  const fetchOrderStates = () => getOrderStates().then(orderStates => {
    let states = orderStates.sort(sortSates)
    if (isDelivery) {
      states = states.filter(s => s.value >= 60)
    }
    setOrderStates(states)
  })

  const loadPaymentMethods = () =>
    getPaymentMethods().then(data => {
      // console.log('loadPaymentMethods', data)
      setPaymentMethods(data)
    })


  const addToCart = (item) => {
    let newItem = true
    let totalQty = 0
    const productsList = cart.productsList?.map(prod => {
      if (item.id === prod.id) {
        prod.qty = item.qty
        newItem = false
      }
      totalQty += prod.qty
      return prod
    })

    if (newItem) {
      totalQty += item.qty
      productsList?.push(item)
    }

    setCart({ ...cart, productsList, totalQty })
  }

  const getPaymentMethodName = ({ order, previousText = 'Método de pago: ' }) => {
    // console.log('getPaymentMethodName', order, paymentMethods)
    if (!paymentMethods || !order.paymentMethod)
      return null

    const name = paymentMethods.find(p => p.method === order.paymentMethod)?.name
    return previousText + name
  }

  const value = {
    layoutContainerRef,
    modules,
    isAuth,
    isDev,
    user,
    client,
    isAdmin,
    isDelivery,
    isChef,
    clients,
    config,
    cart,
    paymentMethods,
    businessInfo,
    regions,
    orderStates,
    products,
    productsFiltered,
    productsCategories,
    staffs,
    productGroups,
    beepers,
    search,
    showFooter,
    setShowFooter,
    setSearch,
    addToCart: addToCart,
    setCart,
    getPaymentMethodName,
    online: navigator.onLine,
    setUser,
    activateAuth: () => { },
  }

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  )
}

const context = {
  Provider,
  Consumer: Context.Consumer
}

export default context